import React from 'react';
import { Globals, Breakpoints } from '../styles/global';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import showdown from 'showdown';
import styled from '@emotion/styled';
import {
  OrangeBox,
  Inner,
  WhiteBox,
  DarkGreyBox,
  BlueBox,
} from '../components/shared';
import { Modal, Button, Box } from '@material-ui/core';
import { ModalBody } from '../components/modal';
import SEO from '../components/seo';

const converter = new showdown.Converter();

interface ModalContent {
  title: string;
  title2: string;
  name: string;
  URL: string;
  copy: string;
}

const Body = (props: { selected: number; content: ModalContent[] }) => {
  const { content, selected } = props;
  const { title, name, URL, copy } = content[selected - 1];
  return (
    <>
      <h2>{name}</h2>
      <h3>{title}</h3>

      {URL && <img src={URL} alt={name} />}
      <div
        className="markdown-holder"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(copy) }}
      />
    </>
  );
};

const UL = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    display: block;
    width: calc(50% - 2rem);
  }

  h1,
  h2,
  h3 {
    width: 100%;
    display: block;
    min-height: 2rem;
  }
  h3 {
    font-weight: 400;
  }
`;

const RepeaterModalPageTemplate = ({
  title,
  content,
  modalContent,
}: {
  title: string;
  content: string;
  modalContent: ModalContent[];
}) => {
  const [open, setOpen] = React.useState(0);
  return (
    <>
      <SEO title={title} />
      <DarkGreyBox
        style={{
          backgroundImage: 'url(/office.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Inner justifyContent="space-between" display="flex">
          <div>
            <h1 className="font-u3">{title}</h1>
            <div
              className="markdown-holder"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
            />
          </div>
        </Inner>
      </DarkGreyBox>
      <WhiteBox>
        <Inner>
          <Box>
            <UL>
              {modalContent.map((item, index) => {
                return (
                  <li className="mb-u3" key={index}>
                    {item.copy !== '' && (
                      <Button onClick={() => setOpen(index + 1)}>
                        <h2 className="font-u1">{item.name}</h2>
                        <h3 className="font-b">{item.title}</h3>
                        {item.title2 !== '' && (
                          <h3 className="font-b">{item.title2}</h3>
                        )}
                      </Button>
                    )}
                    {item.copy === '' && (
                      <>
                        <h2 className="font-u1">{item.name}</h2>
                        <h3 className="font-b">{item.title}</h3>
                        {item.title2 !== '' && (
                          <h3 className="font-b">{item.title2}</h3>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </UL>
          </Box>
        </Inner>
      </WhiteBox>
      <Modal
        open={open > 0}
        onClose={() => {
          setOpen(0);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <ModalBody>
            <Body selected={open} content={modalContent} />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

const RepeaterModalPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title = '', content = '', modalContent = [] },
    },
  },
}) => {
  return (
    <Layout>
      <RepeaterModalPageTemplate
        title={title}
        content={content}
        modalContent={modalContent as ModalContent[]}
      />
    </Layout>
  );
};

export default RepeaterModalPage;

export const RepeaterModalPageQuery = graphql`
  query RepeaterModalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
        modalContent {
          title
          title2
          name
          URL
          copy
        }
      }
    }
  }
`;
