import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outer: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
    paper: {
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      pointerEvents: 'auto',
    },
  }),
);

export const ModalBody = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.outer}>
      <div className={classes.paper}>{children}</div>
    </div>
  );
};
